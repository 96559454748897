import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { v4 } from "uuid";
import Layout from "../../components/Layout";
import Content, { HTMLContent } from "../../components/Content";

// share
import SharePartsOfTroublePagePreview from "./../share-parts-of-trouble-page";
import SharePlacesPageTemplate from "./../share-places-page";
import SharePickupNewsPageTemplate from "./../share-pickup-news-page";
import ShareHistoryPageTemplate from "./../share-history-page";
import ShareInfomationPageTemplate from "./../share-infomation-page";

// images
// import KeyvisualImage from "../../img/medical_hair/image_keyvisual.png";

export const AcneRankingPageTemplate = ({
  title,
  content,
  contentComponent
}) => {
  return (
    <>
      <div className="c-bread">
        <div className="c-bread__inner">
          <div className="c-bread__item">
            <Link className="c-bread__link" to={"/"}>
              <div className="c-bread__text">トップ</div>
            </Link>
          </div>
          <div className="c-bread__item">
            <div className="c-bread__text">ニキビ施術ランキング</div>
          </div>
        </div>
      </div>

      <section className="l-section-lv2 l-main-media-hero">
        <div className="l-main-media-hero__inner">
          <div className="c-simple-title">
            <h2 className="c-simple-title__text">ニキビ施術ランキング</h2>
          </div>

          <section>
            <div className="c-ranking-title -first">
              <h3 className="c-ranking-title__text">ニキビ集中コース</h3>
            </div>
            <p className="u-text">
              医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は。
            </p>
            <div className="u-txt-c u-pc-mt60 u-sp-mt30">
              <Link className="c-border-button -arrow" to={"/machine/"}>
                <p className="c-border-button__text">詳細を確認</p>
              </Link>
            </div>
          </section>

          <section className="u-pc-mt100 u-sp-mt60">
            <div className="c-ranking-title -second">
              <h3 className="c-ranking-title__text">Vビーム</h3>
            </div>
            <p className="u-text">
              医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は。
            </p>
            <div className="u-txt-c u-pc-mt70 u-pc-mt40">
              <Link className="c-border-button -arrow" to={"/machine/"}>
                <p className="c-border-button__text">詳細を確認</p>
              </Link>
            </div>
          </section>

          <section className="u-pc-mt100 u-sp-mt60">
            <div className="c-ranking-title -third">
              <h3 className="c-ranking-title__text">ケミカルピーリング</h3>
            </div>
            <p className="u-text">
              医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は医療脱毛の機器は。
            </p>
            <div className="u-txt-c u-pc-mt70 u-pc-mt40">
              <Link className="c-border-button -arrow" to={"/machine/"}>
                <p className="c-border-button__text">詳細を確認</p>
              </Link>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

AcneRankingPageTemplate.propTypes = {
  title: PropTypes.string
};

const AcneRankingPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AcneRankingPageTemplate title={post.frontmatter.title} />
      <SharePartsOfTroublePagePreview />
      <SharePlacesPageTemplate />
      <SharePickupNewsPageTemplate />
      <ShareHistoryPageTemplate />
      <ShareInfomationPageTemplate />
    </Layout>
  );
};

AcneRankingPage.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default AcneRankingPage;

export const acneRankingPageQuery = graphql`
  query AcneRankingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
